<template>
  <div class="aiz-user-panel">
    <div class="aiz-titlebar mt-2 mb-4">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h1 class="h3">{{ $t('wo-de-ji-fen') }}</h1>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-7 mx-auto">
        <div class="bg-grad-1 text-white rounded-lg overflow-hidden">
          <div class="px-3 pt-3 pb-3">
            <div class="h3 fw-700 text-center">10{{ $t('ji-fen') }} = $1.00{{ $t('qian-bao-yu-e-0') }}</div>
            <div class="opacity-50 text-center">{{ $t('hui-shuai') }}</div>
          </div>
        </div>
      </div>
    </div>

    <br>

    <div class="card">
      <div class="card-header">
        <h5 class="mb-0 h6">{{ $t('ji-fen-zhuan-qu-li-shi') }}</h5>
      </div>
      <div class="card-body">
        <table class="table  mb-0">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ $t('ding-dan-bian-hao') }}</th>
              <th data-breakpoints="lg">{{ $t('ji-fen-0') }}</th>
              <th data-breakpoints="lg">{{ $t('yi-zhuan-huan') }}</th>
              <th data-breakpoints="lg">{{ $t('ri-qi') }}</th>
              <th class="text-right">{{ $t('cao-zuo') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in dataList" :key="i">
                <td>{{i + 1 }}</td>
                <td>
                  {{item.code}}
                </td>
                <td>{{ item.count }}</td>
                <td>
                    <span class="badge badge-inline badge-success" v-if="item.status == 1">{{ $t('shi') }}</span>
                    <span class="badge badge-inline badge-info" v-else>{{ $t('fou') }}</span>
                </td>
                <td>{{ item.date }}</td>

                <td class="text-right">
                  <span class="badge badge-inline badge-success" v-if="item.status == 1">{{ $t('yi-wan-cheng') }}</span>
                  <button  class="btn btn-sm btn-styled btn-primary" v-else>{{ $t('li-ji-zhuan-huan') }}</button>
                </td>

            </tr>
          </tbody>
        </table>
        <div class="aiz-pagination mt-4">
          <pageBar :totalPage="56" :page="51"></pageBar>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import pageBar from '@/components/page'
import {Popup} from 'vant'
export default {
  components: {
    pageBar,
    Popup
  },
  data() {
    return {
      showPopup: false,
      dataList: []
    }
  }
}
</script>